.App {
  text-align: center;
}

body {
  font-family: 'Monocraft', sans-serif;
  background: url("../images/waterfall.jpg") center center fixed;
  background-size: cover;
  overflow: hidden;
  max-width: 100%;
  max-height: 100%;
}

@font-face {
  font-family: "Monocraft";
  src: url('../fonts/Monocraft.otf') format("opentype");
  font-weight: normal;
  font-style: normal;
}