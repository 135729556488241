.Modal {
    background-color: rgba(0, 0, 0, 0.6);
    color: rgb(156, 156, 156);
    text-align: left;
    width: 269px;
    margin: 15px;
    padding: 15px;
    border-radius: 5px;
    max-height: 85vh;
    overflow: auto !important;
}

::-webkit-scrollbar {
    width: 5px;
    background-color: clear;
    border-radius: 15px;
}

::-webkit-scrollbar-thumb {
    background-color: rgb(156, 156, 156);
    border-radius: 10px;
}

.Modal__close-button {
    all: unset;
    color: grey;
    border: none;
    border-width: 1px;
    border-color: grey;
    padding: 4px;
    font-size: large;
}

.Modal__close-button:hover {
    color: red;
    border-color: red;
}